import React from 'react';
import UserContext from '../../../Helpers/UserContext';
import Session from '../../../Helpers/Session';
import userPhoto from '../../../assets/images/photo.png';
import NumberToMoney from '../../../Helpers/numberToMoney';
import { BLOCKBUTTON, BUTTON } from '../../../Components/Forms';
import riderIcon from '../../../assets/images/rider.png';
import { GET_ORDER_STATUS } from "../../../GraphQL/Queries";
import { APIResponse } from "../../../Helpers/General";
import { useLazyQuery } from '@apollo/client';
import ChangeDest from './ChangeDest';
import { useNavigate } from 'react-router-dom';
import CancelOrder from './CancelOrder';
import { getImage } from '../../../Helpers/FileHandling';

const DeliveryFlow = ({
    setDirections,
    distance,
    duration,
    setDistance,
    setDuration,
    amount,
    setAmount
}: {
    setDirections: (direction: google.maps.DirectionsResult) => void,
    distance: number,
    duration: string,
    setDistance: (distance: number) => void,
    setDuration: (duration: string) => void,
    amount: number,
    setAmount: (amount: number) => void
}) => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [packageDetails, setPackageDetails] = React.useState(Session.get('package_details'));
    const [selectedCourier, setSelectedCourier] = React.useState(Session.get("selectedCourier"));
    const [viewMoreModal, setViewMoreModal] = React.useState<boolean>(false);
    const [orderStatus, setOrderStatus] = React.useState<Record<string, any>>({});
    const [changeDest, setChangeDest] = React.useState<boolean>(false);
    const [prevOrderStatus, setPrevOrderStatus] = React.useState("");
    const [deliveryModal, setDeliveryModal] = React.useState<boolean>(false);
    const [voiceCallOption, setVoiceCallOption] = React.useState<boolean>(false);
    const [cancelOrder, setCancelOrder] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const [time, setTime] = React.useState(0);
    const [isWaiting, setIsWaiting] = React.useState(false);

    React.useEffect(() => {
        setPackageDetails(Session.get('package_details'));
    }, [changeDest]);

    const handleStatus = () => {
        let status: string | JSX.Element = '';
        if (orderStatus.rider_accept < 1 && orderStatus.rider_decline < 1) {
            status = "Waiting for Courier's approval";
        } else if (orderStatus.rider_decline === 1) {
            status = "Order Declined";
            Session.remove("selectedCourier");
            Session.remove("package_details");
            Session.remove("deliveryStep");
            Session.showAlert({ str: "The order has been declined by the courier", type: 'error' });
            navigate("/dashboard");
        } else if (orderStatus.rider_accept === 1 && orderStatus.status === "Awaiting Pickup") {
            status = "The Courier is on his way to pickup";
        } else if (orderStatus.status === "Courier Arrived for Pickup") {
            // setIsWaiting(true);
            status = <span>The Courier has arrived for pickup - <span className='text-primary fw-bold'>{formatTime(time)}</span></span>;
        } else if (orderStatus.status === "In Transit") {
            status = "The Courier is on his way to deliver";
            // setIsWaiting(false);
        } else if (orderStatus.status === "Courier Arrived for Delivery") {
            status = "The Courier has arrived at the delivery location";
        } else if (orderStatus.status === "Cancelled") {
            status = "The order has been cancelled";
            Session.showAlert({ str: "The order has been cancelled", type: 'info' });
            Session.remove("selectedCourier");
            Session.remove("deliveryStep");
            navigate("/find-courier");
        } else if (orderStatus.status === "Delivered") {
            status = "The order has been delivered";
            Session.showAlert({ str: "The order has been delivered successfully", type: 'success' });
            setDeliveryModal(true);
        }
        return status;
    }

    React.useEffect(() => {
        if (orderStatus.status === "Courier Arrived for Pickup" && !isWaiting) {
            setIsWaiting(true);
            let intervalId = setInterval(() => {
                setTime(time + 1);
            }, 1000);
            // Don't forget to clear the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
    }, [orderStatus, isWaiting, time]);

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    };


    const [getOrderStatus] = useLazyQuery(GET_ORDER_STATUS, {
        fetchPolicy: "network-only",
        variables: { orderId: packageDetails.id },
        onCompleted(data) {
            setOrderStatus(data.getOrderStatus);
            const newStatus: any = handleStatus();
            if (prevOrderStatus !== newStatus) {
                setPrevOrderStatus(newStatus);
                if (newStatus !== "Order Declined" && newStatus !== "The order has been cancelled" && newStatus !== "The order has been delivered") {
                    Session.showAlert({ str: newStatus, type: 'info' });
                }
            }
        },
        onError(error) {
            APIResponse(error);
        },
    });

    const updateOrderStatus = React.useCallback(async () => {
        try {
            await getOrderStatus();
        } catch (error) {
            console.error(error);
        }
    }, [getOrderStatus]);

    React.useEffect(() => {
        if (orderStatus.status === "Delivered") return;
        updateOrderStatus();
        const intervalId = setInterval(updateOrderStatus, 5000);
        return () => clearInterval(intervalId);
    }, [updateOrderStatus, orderStatus.status]);

    const callUsingMobile = () => {
        if (selectedCourier[0] && selectedCourier[0].phone) {
            const phoneNumber = selectedCourier[0].phone.replace(/\D/g, ''); // Remove non-digit characters
            const telUrl = `tel:${phoneNumber}`;

            // Check if the device supports tel: protocol
            const supportsTel = document.createElement('a');
            supportsTel.href = telUrl;

            if (supportsTel.protocol === 'tel:') {
                // Open the phone app with the number
                window.location.href = telUrl;
            } else {
                // Fallback for devices that don't support tel: protocol
                console.warn('This device does not support making phone calls.');
                // You might want to show an alert or message to the user here
                alert('Unable to initiate call. Please dial ' + phoneNumber + ' manually.');
            }
        } else {
            console.error('Phone number is not available');
            // You might want to show an alert or message to the user here
            alert('Phone number is not available for this merchant.');
        }
    };

    return (
        <>

            {!changeDest && !deliveryModal ? (
                <div className={'brand-modal-map w3-animate-bottom'}>
                    {
                        (orderStatus.status === "Pending" || orderStatus.status === "Awaiting Pickup" || orderStatus.status === "Courier Arrived for Pickup") && (
                            <div className="position-fixed top-0  w-100" >
                                <div className='d-flex align-items-center justify-content-end'>
                                    <BUTTON className=' bg-light w3-round-xlarge mt-3 px-3 py-2 me-2 fw-bold '>
                                        <span className='text-negative text-nowrap'
                                            onClick={() => setCancelOrder(true)}
                                        >Cancel Order</span>
                                    </BUTTON>
                                </div>
                            </div>
                        )
                    }

                    <div className='w-100 brand-modal-content'>
                        <div className='text-center pt-2 pb-2 fw-bold w3-sand'><i className="bi bi-bell pe-2"></i>{handleStatus()}</div>
                        <div className=' px-2 pt-3 bg-light pb-4' >
                            <div className='w3-animate-bottom'>
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='rounded-circle d-flex align-items-center justify-content-center'
                                            style={{ width: '50px', height: '50px' }}
                                        >
                                            <img src={selectedCourier[0].photo ? getImage(selectedCourier[0].photo) : userPhoto} alt={selectedCourier[0].fullname || "Unknown"} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                        </div>
                                        <div>
                                            <p className='fw-bold my-0 fs-6 ms-2'>{selectedCourier[0].fullname ? selectedCourier[0].fullname : "Unknown"}</p>
                                            <p className="mb-0 px-2 py-1 text-primary w3-round-large bg-white">{selectedCourier[0].successful_orders < 2 ? `${selectedCourier[0].successful_orders} Delivery` : `${selectedCourier[0].successful_orders} Deliveries`}</p>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center '>
                                        <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 mx-2'
                                            style={{ width: '40px', height: '40px' }}
                                            onClick={() => navigate("/chat-with-courier")}
                                        ><i className="bi bi-chat-right-text text-dark fs-3 "></i></div>
                                        <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 ms-2'
                                            style={{ width: '40px', height: '40px' }}
                                            onClick={() => setVoiceCallOption(true)}>
                                            <i className="bi bi-telephone-fill text-dark fs-3"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                    <div className='d-flex flex-column justify-content-start'>
                                        <p className='fw-bold my-0 fs-6'>{selectedCourier[0].bike_plate_number}</p>
                                        <p className=' my-0 '>{selectedCourier[0].bike_brand}, {selectedCourier[0].bike_model}</p>
                                    </div>
                                    <div className=''>
                                        <img src={riderIcon} alt="riderIcon" width={50} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-end mt-2 pb-3'>
                                    <div className='d-flex flex-column justify-content-start align-items-start'>
                                        <div className="d-flex align-items-center mb-2">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                    <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                </svg>
                                            </div>
                                            <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{packageDetails.pickup_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                        </div>

                                        <div className='d-flex align-items-end justify-content-between'>
                                            <div className="d-flex align-items-center">
                                                <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{packageDetails.receiver_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                            </div>

                                        </div>
                                    </div>
                                    {(orderStatus.status === "Pending" || orderStatus.status === "Awaiting Pickup" || orderStatus.status === "In Transit" || orderStatus.status === "Courier Arrived for Pickup") && (
                                        <BUTTON
                                            className={"border-primary pending text-dark fw-bold my-0 py-2 "}
                                            onClick={() => setChangeDest(true)}
                                        >
                                            Change
                                        </BUTTON>
                                    )}

                                </div>


                                <div className='d-flex justify-content-between align-items-center mb-2'>
                                    <p className=' my-0 fs-6 ms-2'>Delivery fee</p>
                                    <p className='fw-bold my-0 fs-6 ms-2'>&#8358;&nbsp;{NumberToMoney(packageDetails?.amount || 0)}</p>
                                </div>

                                <div className='d-flex  align-items-center w-100 px-2 py-3 w3-light-gray w3-round-large mt-2'>
                                    <p className="mb-0 ps-2 text-primary fs-6 fw-bold" role='button'
                                        onClick={() => setViewMoreModal(true)}
                                    >Package details</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Menu /> */}
                </div>
            ) : (
                <ChangeDest setChangeDest={setChangeDest}
                    amount={amount}
                    distance={distance}
                    duration={duration}
                    setAmount={setAmount}
                    setDirections={setDirections}
                    setDistance={setDistance}
                    setDuration={setDuration}
                />
            )}

            {packageDetails && viewMoreModal && (
                <div
                    className={'brand-modal w3-animate-zoom w-100' + (viewMoreModal ? 'd-flex' : 'd-none')}
                    style={{ alignItems: 'center' }}
                >
                    <div
                        className='w-100 bg-light p-3 mx-3'
                        style={{ borderRadius: '16px', maxWidth: '400px' }}
                    >
                        <div className='d-flex align-items-center justify-content-between w-100 px-2 border-bottom border-muted'>
                            <div className="">
                                <h3 className='text-start my-0 pb-1'>Package Details</h3>
                                <p className="text-start text-muted mb-1">Here is the package details</p>
                            </div>
                            <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px' }}>
                                <i className='bi bi-x fs-3'
                                    onClick={() => setViewMoreModal(false)}
                                    role='button'
                                ></i>
                            </div>
                        </div>
                        <div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Package Name</p>
                                <h3 className="text-dark my-0">{packageDetails.package_name}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Pick Up</p>
                                <h3 className="text-dark my-0">{packageDetails.pickup_address}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Drop Off</p>
                                <h3 className="text-dark my-0">{packageDetails.receiver_address}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Package Quantity</p>
                                <h3 className="text-dark my-0">{packageDetails.package_quantity > 1 ? "Multiple" : "Single"} - {packageDetails.package_quantity}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Receiver's Name</p>
                                <h3 className="text-dark my-0">{packageDetails.receiver_name}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Receiver's Number</p>
                                <h3 className="text-dark my-0">{packageDetails.receiver_phone}</h3>
                            </div>
                        </div>

                    </div>
                </div>
            )}

            {deliveryModal && orderStatus.status === "Delivered" && (
                <>
                    <div className={'brand-modal w3-animate-bottom'}>
                        <div className='w-100 brand-modal-content bg-light p-2'>
                            <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center mt-3 ms-2' style={{ width: '30px', height: '30px' }}>
                                <i className='bi bi-x fs-3'

                                    onClick={() => {
                                        Session.remove("selectedCourier");
                                        Session.remove("package_details");
                                        Session.remove("deliveryStep");
                                        navigate("/dashboard");
                                    }}
                                    role='button'
                                ></i>
                            </div>

                            <h3 className='fs-3 fw-bold text-center mt-4'>
                                Package Delivered
                            </h3>

                            <div className='d-flex align-items-center justify-content-center'>
                                <div className='rounded-circle d-flex align-items-center justify-content-center'
                                    style={{ width: '90px', height: '90px' }}
                                >
                                    <img src={selectedCourier[0].photo ? getImage(selectedCourier[0].photo) : userPhoto} alt={selectedCourier[0].fullname || "Unknown"} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                </div>
                            </div>
                            <p className='text-center fw-bold fs-4 mt-3'>{selectedCourier[0].fullname}</p>


                            <div>
                                <p className='text-center fw-bold fs-5'>Rate the courier</p>

                                <div className="d-flex align-items-center justify-content-center">
                                    <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                                    <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                                    <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                                    <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                                    <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                                </div>

                            </div>
                            <div className='px-2'>
                                <textarea name="message" id="" rows={4} className='mt-3 form-control '
                                    style={{ resize: 'none' }}
                                ></textarea>
                            </div>

                            <BLOCKBUTTON
                                className={"btn-primary "}
                                onClick={() => {
                                    Session.remove("selectedCourier");
                                    Session.remove("package_details");
                                    Session.remove("deliveryStep");
                                    navigate("/dashboard");
                                }}
                            >
                                Submit
                            </BLOCKBUTTON>
                            <div className='w-100 mb-5'>

                                <p className='text-center text-primary fw-bold fs-6 text-decoration-underline mt-3'
                                    onClick={() => navigate("/delivery-details")}
                                >Delivery Info</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {voiceCallOption && (
                <>
                    <div className="brand-modal">
                        <div className='w-100 brand-modal-content bg-light px-3'>
                            <h4 className='text-start py-3 my-0'>Call Merchant</h4>
                            <div className=''>
                                <div className='w-100 w3-round-large mb-3' style={{ width: '40px', height: '40px' }}
                                    onClick={() => {
                                        navigate("/call")
                                    }}>
                                    <div className='d-flex align-items-center justify-content-start'>
                                        <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3'
                                            style={{ width: '40px', height: '40px' }}
                                        ><img src="./images/telephone-dark.png" alt="" className='ms-0' width={23} /></div>
                                        <p className='ms-3 mb-0'>Call in app</p>
                                    </div>
                                </div>

                                <div className='w-100 w3-round-large mb-4' style={{ width: '40px', height: '40px' }}
                                    onClick={() => {
                                        callUsingMobile();
                                    }}>
                                    <div className='d-flex align-items-center justify-content-start'>
                                        <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3'
                                            style={{ width: '40px', height: '40px' }}
                                        ><img src="./images/telephone-dark.png" alt="" className='ms-0' width={23} /></div>
                                        <p className='ms-3 mb-0'>Call with mobile</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )}

            {cancelOrder && (
                <CancelOrder setCancelOrder={setCancelOrder} />
            )}
        </>
    )
}

export default DeliveryFlow;