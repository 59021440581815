import React from "react";
import { Link } from "react-router-dom";
import UserContext from "../../../Helpers/UserContext";
import formatNumberToMoney from "../../../Helpers/numberToMoney";
import { BLOCKBUTTON, INPUT } from "../../../Components/Forms";
import { Validate } from "../../../Helpers/Validate";
import Session from "../../../Helpers/Session";
import LottieAnimation from "../../../Components/LottieAnimation";
import SuccessAnime from "../../../assets/lottie/successful.json";
import { useMutation } from "@apollo/client";
import { FUND_WALLET } from "../../../GraphQL/Mutations";
import { APIResponse } from "../../../Helpers/General";
import { Loading } from "../../../Components/Loading";
import numberToMoney from "../../../Helpers/numberToMoney";
import { FundWalletButton } from "../../../Components/Paystack";

const FundAccount = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [hideBalance, setHideBalance] = React.useState<boolean>(false);
    const balance = user?.balance || 0.00;
    const [amountToFund, setAmountToFund] = React.useState<string>("");
    const [successFund, setSuccessFund] = React.useState<boolean>(false);
    const [loadingBT, setLoadingBT] = React.useState<boolean>(false);


    const [FundWallet, { loading: loadingFundWallet }] = useMutation(FUND_WALLET, {
        variables: {
            amount: +amountToFund,
        },
        onCompleted: (data) => {
            if (data.fundWallet) {
                setSuccessFund(true);
            }
            myContext.setUser({ ...user, balance: data.fundWallet });
            Session.set("user", { ...user, balance: data.fundWallet });
        },
        onError: (error) => {
            JSON.stringify(error, null, 2)
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });


    // const handleExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    //     if (value.length > 4) {
    //         value = value.slice(0, 4); // Limit to 4 digits
    //     }

    //     if (value.length >= 2) {
    //         value = `${value.slice(0, 2)}/${value.slice(2)}`;
    //     }

    //     setCardExpiryDate(value);

    //     // If the expiry date is fully entered (MM/YY), move focus to the CVV input
    //     if (value.length === 5 && cvvInputRef.current) {
    //         cvvInputRef.current.focus();
    //     }
    // };


    const toggleBalanceVisibility = () => {
        setHideBalance(!hideBalance);
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let amountInput = e.target.value.replace(/\D/g, '');
        setAmountToFund(amountInput);
    };


    const handleFundWallet = () => {
        if (!Validate.positiveInteger(+amountToFund)) {
            Session.showAlert({ str: 'Please enter the amount to fund', type: 'warning' });
            return;
        }
        FundWallet();
    };

    if (loadingFundWallet || loadingBT) return <Loading fullPage={true} message="Funding Wallet..." />;
    return (
        <>
            <div className="w3-animate-left">
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="/wallet"
                            >
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Fund Wallet</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className="container h-100">
                    <div className="mt-4 mb-3">
                        <div>
                            <p className="text-center mb-0 text-dark">Wallet Balance</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <h2 className="text-center text-dark fw-normal my-0">
                                {hideBalance ? "*".repeat(numberToMoney(+balance.toFixed(2)).toString().length) : (<span>&#8358;{formatNumberToMoney(+balance.toFixed(2))}</span>)}
                            </h2>
                            <i
                                className={`bi ${hideBalance ? "bi-eye" : "bi-eye-slash"} ms-2 text-dark fs-2`}
                                onClick={toggleBalanceVisibility}
                                style={{ cursor: "pointer" }}
                            ></i>
                        </div>
                    </div>
 
                    <>
                        <div className="mt-4">
                            <label>Enter amount</label>

                            <INPUT
                                type="number"
                                value={amountToFund}
                                onChange={handleAmountChange}
                                pattern="[0-9]*"
                                placeholder="Amount"
                            />
                        </div>

                        <div className="container position-fixed bottom-0 start-50 translate-middle-x w-100 d-flex justify-content-center pb-3 bg-light">
                        
                            <div className={(+amountToFund < 1 ? 'd-none' : 'd-block w-100')}>

                                <FundWalletButton fullname={user.fullname} email={user.email} amount={+amountToFund} phone={user.phone} onSuccess={handleFundWallet} onClose={() => setLoadingBT(false)} />
                            </div>
                        </div>
                    </>

                </div>

                <div
                    className={'brand-modal w3-animate-bottom ' + (successFund ? 'd-flex' : 'd-none')}
                    onTouchMove={() => setSuccessFund(false)}
                >
                    <div
                        className='container brand-modal-content bg-light'
                    >
                        <div className='w3-center'>
                            <LottieAnimation
                                animationData={SuccessAnime}
                                loop={true}
                                width={150}
                                height={150}
                                autoplay={true}
                            />
                        </div>
                        <div>
                            <h3 className="text-center my-0">Funding Successful</h3>
                            <p className='text-center text-wrap text-muted'>
                                The sum of <span className='fw-bold text-primary'>&#8358;{`${formatNumberToMoney(+amountToFund)}`}</span> has been deposited into your wallet.
                            </p>
                        </div>
                        <div className='d-flex justify-content-around align-items-center'>
                            <BLOCKBUTTON
                                className='btn btn-primary mt-4 mb-3'
                                onClick={() => {
                                    setSuccessFund(false);
                                    setAmountToFund('');
                                }}
                            >
                                Continue
                            </BLOCKBUTTON>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FundAccount;