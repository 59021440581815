import React, { useContext, Suspense } from 'react';
import { Loading } from '../../../Components/Loading';
import UserContext from '../../../Helpers/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_NOTIFICATION } from '../../../GraphQL/Queries';
import Session from '../../../Helpers/Session';
import FormatDate from '../../../Helpers/FormatDate';

const Notification: React.FC = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(GET_ALL_NOTIFICATION, {
        fetchPolicy: 'network-only',
        variables: {
            offset: 0
        },
        onCompleted: (data) => {
            // No need to set state here, as we can directly use the data from the query
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: "error" });
            return;
        }
    });

    if (loading) {
        return <Loading fullPage={true} message={'Loading notifications...'} timing={false} />;
    }

    if (error) {
        return <div>Error loading notifications. Please try again later.</div>;
    }

    const notifications = data?.getAllNotifications || [];

    return (
        <>
            <div className="w3-animate-left">
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <i className="bi bi-arrow-left text-light fs-1 ps-3 fw-bolder"
                                onClick={() => navigate(-1)}
                            ></i>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Notifications</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <Suspense fallback={<Loading />}>
                    <div className="container my-3">
                        {notifications.length > 0 ? (
                            notifications.map((notification: Record<string, any>) => (
                                <div key={notification.id} className='px-3 py-2 shadow-none bg-light w3-round-large mb-2'>
                                    <div>{notification.message}</div>
                                    <p className='text-muted mb-0 pt-2'>{FormatDate(notification.created_at)}</p>
                                </div>
                            ))
                        ) : (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <i className="bi bi-bell-fill no-notifications" style={{ fontSize: '5rem' }}></i>

                                <p className='text-muted fw-bolder text-center mb-0 pt-2'>No notifications</p>
                            </div>
                            
                        </div>
                        )}
                        
                    </div>
                </Suspense>
            </div>
        </>
    );
};

export default Notification;
