import React from "react";
import { Link } from "react-router-dom";

// FAQ data
const faqData = [
    {
        question: "What are your on-demand courier services?",
        answer:
            "Gokari On-demand courier services provide fast and efficient delivery of packages and parcels, often with same-day or next-day delivery options. These services are ideal for businesses and individuals who need immediate or urgent delivery of items."
    },
    {
        question: "How can your on-demand courier services accommodate our specific delivery needs, such as same-day or time-sensitive deliveries?",
        answer:
            "Our GOkari on-demand courier app is designed to be flexible and responsive to our clients' specific delivery requirements. Whether you need same-day deliveries, time-sensitive shipments, or specialized handling, just download the app and request for a ride. we have the capabilities to meet your needs efficiently and reliably."
    },
    {
        question: "How does Gokari work?",
        answer:
            "Gokari is an on-demand courier delivery service in Nigeria that allows users to request package deliveries through their mobile app or website. Users can track their deliveries in real-time and receive notifications on the status of their package."
    },
    {
        question: "What areas in Nigeria does Gokari operate in?",
        answer:
            "Gokari currently operates in major cities in Nigeria, including Lagos, Abuja, and Port Harcourt. They are continually expanding their service areas to reach more customers."
    },
    {
        question: "What type of packages can I send using Gokari?",
        answer:
            "Gokari can deliver a wide range of packages, including documents, parcels, and food deliveries. They have specific guidelines on what can be delivered."
    },
    {
        question: "How does Gokari ensure the safety of my package during delivery?",
        answer:
            "Gokari employs trained and reliable delivery personnel who handle packages with care."
    },
    {
        question: "How much does it cost to use Gokari's services?",
        answer:
            "The cost of using Gokari's services varies depending on the distance of the delivery and the size of the package, and any additional services required. You can get a quote through the app or website before confirming your delivery."
    },
    {
        question: "How can I track my package with Gokari?",
        answer:
            "Once you have placed an order with Gokari, you can track your package in real-time using the tracking feature on the app. You will also receive notifications on the status of your delivery."
    },
    {
        question: "What payment methods does Gokari accept?",
        answer:
            "Gokari accepts various payment methods, including online payments through the app using credit/debit cards or mobile wallets. Cash on delivery options is not available for now."
    }
];


const Faqs = () => {
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

    const handleToggle = (index: number) => {
        // Toggle between opening and closing the accordion item
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="w3-animate-left">
        <div className='bg-primary w-100'>
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="fs-3 ps-3" to="/account">
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link>
                        </div>
                        <div className='text-center fs-3 text-light'>
                            <h3 className='text-light mb-0'>FAQs</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
            <div className="accordion">
                {faqData.map((faq, index) => (
                    <div key={index} className="accordion-item">
                        <div
                            className="accordion-header d-flex justify-content-between align-items-start"
                            onClick={() => handleToggle(index)}
                        >
                            <h3 className="my-0">{faq.question}</h3>
                            <span>{activeIndex === index ? <i className="bi bi-dash-lg"></i> : <i className="bi bi-plus-lg"></i>}</span>
                        </div>
                        {activeIndex === index && (
                            <div className="accordion-content">
                                <p className="fs-5">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Faqs;