import { DateTime } from 'luxon';
import { PaystackButton } from 'react-paystack';

export const FundWalletButton = (
    { fullname, email, amount, phone, onSuccess, onClose }: { fullname: string, email: string, amount: number, phone: string, onSuccess: () => void, onClose: () => void },
) => {
    const config = {
        reference: new Date().getTime().toString(),
        email,
        amount: amount * 100,
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || '',
        firstname: fullname.split(' ')[0],
        lastname: fullname.split(' ')[1] || '',
        phone,
        currency: 'NGN',
    };


    return (
        <>
            <PaystackButton text='Fund Wallet' onSuccess={onSuccess} onClose={onClose} className='btn btn-primary w-100 py-3 mt-2 mb-3' {...config} />
        </>
    )
};



export const PaymentViaBankTransfer = async ({ email, amount }: { email: string, amount: number }) => {
    const url = 'https://api.paystack.co/charge';
    const secretKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;

    const data = {
        email: email,
        amount: amount,
        bank_transfer: {
            account_expires_at: DateTime.now().plus({ minutes: 15 }).toISO(),
        },
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${secretKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();


        return result;
    } catch (error) {
        console.error('Error charging customer:', error);
        throw error;
    }
};

interface FetchBanksParams {
    country?: string;
    use_cursor?: boolean;
    perPage?: number;
    pay_with_bank_transfer?: boolean;
    pay_with_bank?: boolean;
    enabled_for_verification?: boolean;
    next?: string;
    previous?: string;
    gateway?: string;
    type?: string;
    currency?: string;
}

export const fetchBanks = async (params: FetchBanksParams) => {
    const url = new URL('https://api.paystack.co/bank');
    const secretKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;

    // Append query parameters to the URL
    Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined) {
            url.searchParams.append(key, String(value));
        }
    });

    try {
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${secretKey}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        return result.data;
    } catch (error) {
        console.error('Error fetching banks:', error);
        throw error
    }
};


export const resolveBankAccount = async ({account_number, bank_code}: {account_number: string, bank_code: string}) => {
    const url = `https://api.paystack.co/bank/resolve?account_number=${account_number}&bank_code=${bank_code}`;
    const secretKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${secretKey}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error resolving account:', error);
    }
};

interface TransferRecipientData {
    type?: string;
    name: string;
    account_number: string;
    bank_code: string;
    currency: string;
  }
  
  export const createTransferRecipient = async ({type = 'nuban', name, account_number, bank_code, currency}: TransferRecipientData) => {
    const url = 'https://api.paystack.co/transferrecipient';
    const secretKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${secretKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type,
          name,
          account_number,
          bank_code,
          currency
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error creating transfer recipient:', error);
      throw error
    }
  };

  interface TransferData {
    source?: string;
    amount: number;
    reference: string;
    recipient: string;
    reason: string;
}

export const initiateTransfer = async ({
    source = "balance",
    amount,
    reference,
    recipient,
    reason
}: TransferData): Promise<any> => {
    const url = 'https://api.paystack.co/transfer';
    const secretKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;

    if (!secretKey) {
        throw new Error('Paystack secret key is not defined');
    }

    if (!amount || !reference || !recipient || !reason) {
        throw new Error('Missing required parameters');
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${secretKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                source,
                amount,
                reference,
                recipient,
                reason
            }),
        });

        console.log("Initiate transfer:", response);

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error: ${response.status} - ${response.statusText} - ${errorText}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error initiating transfer:', error);
        throw error;
    }
};