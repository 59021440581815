const CONFIG = {
    PACKAGE_CATEGORIES: [
        "Mobile phones",
        "Single",
        "Mobile phone accessories",
        "Electronic devices",
        "Electronic accessories",
        "Fashion items (bags, shoes, clothes, etc)",
        "Dry Food items",
        "Jewellries",
        "Fresh Food items",
        "Care Items",
        "Personal Effects"
    ]
}

export default CONFIG;