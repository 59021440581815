import React from "react";
import { useNavigate } from "react-router-dom";
import Session from "../../../Helpers/Session";
import NumberToMoney from "../../../Helpers/numberToMoney";
import { getImage } from "../../../Helpers/FileHandling";


const DeliveryDetails = () => {
    const courier = Session.get("selectedCourier")[0];
    const package_details = Session.get("package_details");
    const navigate = useNavigate();


    return (
        <>
            <div className="w3-animate-left">
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>

                            <i className="bi bi-arrow-left text-light fw-bolder"
                            
                                onClick={()=>{
                                    Session.remove("selectedCourier");
                                    Session.remove("package_details");
                                    Session.remove("deliveryStep");
                                    navigate("/dashboard");
                                }}
                            ></i>

                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Delivery Details</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>

                <div className="px-3">
                    <p className="mt-3 fw-bold text-center ">September 22, 2022 at 11:00 AM</p>

                    <p className="mb-0 fs-6 mt-3 mb-2">Courier details</p>
                    <div key={courier.user_id} className={'d-flex align-items-center p-2 justify-content-between border border-2 w3-round-large border-muted bg-light shadow-none'}
                    >
                        <div className="d-flex align-items-center">
                            <div className='rounded-circle d-flex align-items-center justify-content-center'
                                style={{ width: '50px', height: '50px' }}
                            >
                                <img src={getImage(courier.photo)} alt={courier.fullname} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                            </div>
                            <div className="d-flex flex-column align-items-start ms-2">
                                <p className="my-0 fw-bold fs-5">{courier.fullname}</p>
                                <p className="mb-0">{courier.bike_plate_number}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start flex-column ">
                            <p className="mb-0 px-2 py-1 text-primary w3-round-large bg-white">{courier.successful_orders < 2 ? `${courier.successful_orders} Delivery` : `${courier.successful_orders} Deliveries`}</p>
                            <div className="d-flex align-items-center ">
                                <i className="bi bi-star-fill text-warning fs-4 mx-1"></i>
                                <i className="bi bi-star-fill text-warning fs-4 mx-1"></i>
                                <i className="bi bi-star-fill text-warning fs-4 mx-1"></i>
                                <i className="bi bi-star-fill text-warning fs-4 mx-1"></i>
                            </div>
                        </div>

                    </div>

                    <p className="text-muted fw-bold fs-5 mt-4 mb-2">Pick up and Drop off Location</p>
                    <div className="d-flex flex-column justify-content-start align-items-start">
                        <div className="d-flex align-items-center  pt-2">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                    <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                </svg>
                            </div>
                            <p className="ms-2 my-0 fw-bold">{package_details.pickup_address}</p>

                        </div>

                        <div>
                            <i className="bi bi-three-dots-vertical text-muted fs-3"></i>
                        </div>
                        <div className="d-flex align-items-center ">
                            <div>
                                <i className="bi bi-geo-alt-fill text-primary fs-3"></i>
                            </div>
                            <p className="ms-2 my-0 fw-bold ">{package_details.receiver_address}</p>
                        </div>
                    </div>

                    <div>
                        <h3 className="fw-bold fs-4 mt-4 mb-1">Package Basic Details</h3>
                        <div className="mt-2">
                            <p className="text-muted mb-0">Package Name</p>
                            <h3 className="text-dark my-0">{package_details.package_name}</h3>
                        </div>
                        <div className="mt-3">
                            <p className="text-muted mb-0">Package Quantity</p>
                            <h3 className="text-dark my-0">{package_details.package_quantity > 1 ? "Multiple" : "Single"} - {package_details.package_quantity}</h3>
                        </div>
                        <div className="mt-3">
                            <p className="text-muted mb-0">Delivery Fee</p>
                            <h3 className="text-dark my-0">&#8358;&nbsp;{NumberToMoney(package_details.amount)}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};



export default DeliveryDetails