import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BLOCKBUTTON, BUTTON, INPUT, SELECT_DROPDOWN, SELECTOPTION } from "../../../Components/Forms";
import Session from "../../../Helpers/Session";
// import { GET_PACKAGE_CATEGORIES } from '../../../GraphQL/Queries';
import { useQuery } from "@apollo/client";
import { Validate } from "../../../Helpers/Validate";
import UserContext from "../../../Helpers/UserContext";
import { Loading } from "../../../Components/Loading";
import UserCurrentLocation from "../../../Helpers/UserCurrentLocation";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { APIResponse, validateNigerianPhoneNumber } from "../../../Helpers/General";
import CONFIG from "../../../config/config";

const SendPackage = () => {
    const navigate = useNavigate();
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [pickUp, setPickUp] = React.useState<string>('');
    const [pickUpCord, setPickUpCord] = React.useState<google.maps.LatLngLiteral>();
    const [dropOffCord, setPDropOffCord] = React.useState<google.maps.LatLngLiteral>();
    const [dropOff, setDropOff] = React.useState<string>('');
    const [packageName, setPackageName] = React.useState<string>('');
    const [packageQuantity, setPackageQuantity] = React.useState<string>('');
    const [packageCategory, setPackageCategory] = React.useState<string>('');
    const [inputQuantity, setInputQuantity] = React.useState<number>(0);
    const [receiverName, setReceiverName] = React.useState<string>('');
    const [receiverPhone, setReceiverPhone] = React.useState<string>('');
    const [showInputQuantity, setShowInputQuantity] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [confirmModal, setConfirmModal] = React.useState<boolean>(false);
    const [userLocation, setUserLocation] = React.useState({ latitude: 0, longitude: 0 });
    const [packageCategories, setPackageCategories] = React.useState<Record<string, any>[]>([]);


    const handleUserLocation = async () => {
        const miiLocation = await UserCurrentLocation() || { latitude: 0, longitude: 0 };
        setUserLocation(miiLocation);
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
    
        // Only allow numeric input
        const isNumeric = /^[0-9]*$/.test(value);
    
        // Allow input only if it's exactly 11 digits or empty (for clearing the field)
        if (isNumeric && (value.length <= 11)) {
            setReceiverPhone(value);
        }
      };

    // const { loading } = useQuery(GET_PACKAGE_CATEGORIES, {
    //     fetchPolicy: "network-only",
    //     onCompleted: (data) => {
    //         if (data.getPackageCategories) {
    //             setPackageCategories(data.getPackageCategories);
    //         }
    //     },
    //     onError: (error) => {
    //         APIResponse(error)
    //     }
    // });


    const {
        ready: pickupReady,
        value: pickupValue,
        suggestions: { status: pickupStatus, data: pickupData },
        setValue: setPickupValue,
        clearSuggestions: clearPickupSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: { componentRestrictions: { country: "ng" } },
        debounce: 300,
    });

    const {
        ready: dropoffReady,
        value: dropoffValue,
        suggestions: { status: dropoffStatus, data: dropoffData },
        setValue: setDropoffValue,
        clearSuggestions: clearDropoffSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: { componentRestrictions: { country: "ng" } },
        debounce: 300,
    });

    const handlePackageQuantityChange = (e: string) => {
        const selectedQuantity = e;
        setPackageQuantity(selectedQuantity);
        setShowInputQuantity(selectedQuantity === 'Multiple');

        if (selectedQuantity === 'Single') {
            setInputQuantity(1);
        } else if (selectedQuantity === 'Multiple') {
            setInputQuantity(2);
        }
    };
    const handlePackageCategory = (e: string)=>{
        console.log(e)
        setPackageCategory(e)
    }

    const handleInputQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const quantity = parseInt(e.target.value);
        if (packageQuantity === 'Multiple' && quantity < 2) {
            setErrorMessage('Multiple quantity should be 2 and above.');
        } else {
            setErrorMessage('');
            setInputQuantity(quantity);
        }
    };

    const handleConfirm = () => {
        if (inputQuantity === 0) {
            Session.showAlert({ str: 'Please enter quantity', type: 'error' });
            return;
        }
        if (!pickUp || !Validate.string(pickUp)) {
            Session.showAlert({ str: 'Please enter pick up location', type: 'error' });
            return;
        }
        if (!dropOff || !Validate.string(dropOff)) {
            Session.showAlert({ str: 'Please enter drop off location', type: 'error' });
            return;
        }
        if (!packageName || !Validate.string(packageName)) {
            Session.showAlert({ str: 'Please enter package name', type: 'error' });
            return;
        }
        if (!receiverName || !Validate.string(receiverName)) {
            Session.showAlert({ str: 'Please enter receiver name', type: 'error' });
            return;
        }
        if (!validateNigerianPhoneNumber(receiverPhone)) {
            Session.showAlert({ str: 'Please enter receiver phone', type: 'error' });
            return;
        }
        if (!packageQuantity || !Validate.string(packageQuantity)) {
            Session.showAlert({ str: 'Please enter package quantity', type: 'error' });
            return;
        }
        if (packageQuantity === 'Multiple' && inputQuantity < 2) {
            Session.showAlert({ str: 'Multiple quantity should be 2 and above.', type: 'error' });
            return;
        }
        if (packageQuantity === 'Single' && inputQuantity < 1) {
            Session.showAlert({ str: 'Single quantity should be 1 and above.', type: 'error' });
            return;
        }
        // setReceiverPhone(Validate.formatPhone(receiverPhone));

        setConfirmModal(true);
    };

    const handlePickUpSelect = async (address: string) => {
        setPickupValue(address, false);
        clearPickupSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            setPickUp(address);
            setPickUpCord({ lat, lng });
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleDropOffSelect = async (address: string) => {
        setDropoffValue(address, false);
        clearDropoffSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            setDropOff(address);
            setPDropOffCord({ lat, lng });
        } catch (error) {
            console.error("Error: ", error);
        }
    };
    React.useEffect(() => {
        handleUserLocation();
    })

    const handleUseCurrentLocation = async () => {
        handleUserLocation();
        if (userLocation.latitude && userLocation.longitude) {
            try {
                const results = await getGeocode({ location: { lat: userLocation.latitude, lng: userLocation.longitude } });
                if (results[0]) {
                    const address = results[0].formatted_address;
                    setPickUp(address);
                    setPickupValue(address, false);
                    setPickUpCord({ lat: userLocation.latitude, lng: userLocation.longitude });
                    clearPickupSuggestions();
                }
            } catch (error) {
                console.error('Error: ', error);
            }
        }
    };
    // if (loading) {
    //     return <Loading />
    // }
    return (
        <>
            <div className="w3-animate-left">
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="/"
                            >
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Send Package</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className="container mt-3" style={{ marginBottom: "100px" }}>
                    <div>
                        <p className=" mb-0 text-dark fw-bold">Set Pick up & Drop off Locations</p>

                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center flex-column pt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                    <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                </svg>
                                <i className="bi bi-three-dots-vertical text-muted fs-3"></i>
                                <i className="bi bi-geo-alt-fill text-primary fs-3"></i>
                            </div>
                            <div className="d-flex align-items-center flex-column w-100 ms-2">
                                <div className="w-100 mb-2 position-relative">
                                    <input
                                        value={pickupValue}
                                        onChange={(e) => setPickupValue(e.target.value)}
                                        disabled={!pickupReady}
                                        placeholder="Enter pick up location"
                                        className="form-control w-100"
                                    />
                                    {pickupStatus === "OK" && (
                                        <ul className="list-group position-absolute w-100 z-2">
                                            <li className="list-group-item text-primary" onClick={handleUseCurrentLocation}>
                                                Use Current Location
                                            </li>
                                            {pickupData.map((suggestion) => (
                                                <li
                                                    key={suggestion.place_id}
                                                    onClick={() => handlePickUpSelect(suggestion.description)}
                                                    className="list-group-item"
                                                >
                                                    {suggestion.description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                </div>
                                <div className="w-100">
                                    <input
                                        value={dropoffValue}
                                        onChange={(e) => setDropoffValue(e.target.value)}
                                        disabled={!dropoffReady}
                                        placeholder="Enter drop off location"
                                        className="form-control w-100"
                                    />
                                    {dropoffStatus === "OK" && (
                                        <ul className="list-group position-absolute w-100 z-2">
                                            {dropoffData.map((suggestion) => (
                                                <li
                                                    key={suggestion.place_id}
                                                    onClick={() => handleDropOffSelect(suggestion.description)}
                                                    className="list-group-item"
                                                >
                                                    {suggestion.description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <p className="mb-0 text-dark fw-bold">Add Package Details</p>
                        <div className="mt-3">
                            <label>Package Name</label>

                            <INPUT
                                type="text"
                                className="form-control"
                                value={packageName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPackageName(e.target.value)}
                                placeholder="Name"
                            />
                            <label className="mt-2">Package Category</label>

                            <SELECT_DROPDOWN
                                items={CONFIG.PACKAGE_CATEGORIES}
                                placeholder="Select package quantity"
                                onSelect={handlePackageCategory}
                                value={packageCategory}
                                // showSearch={true}
                            />
                            <div className="d-flex align-items-center w-100">
                                <div className={showInputQuantity ? "w-50" : "w-100"}>
                                    <label className="mt-2">Package Quantity</label>

                                    <SELECT_DROPDOWN
                                        items={["Single", "Multiple"]}
                                        placeholder="Select package quantity"
                                        onSelect={handlePackageQuantityChange}
                                        value={packageQuantity}
                                        // showSearch={true}
                                    />
                                </div>
                                {showInputQuantity && (
                                    <div className="w-50 ms-3">
                                        <label className="mt-2">Input Quantity</label>

                                        <INPUT
                                            type="number"
                                            value={inputQuantity}
                                            pattern="[0-9]*"
                                            onChange={handleInputQuantityChange}
                                            placeholder="Quantity"
                                        />

                                    </div>
                                )}
                            </div>
                            <div>{errorMessage && <small className="text-negative">{errorMessage}</small>}</div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <p className="mb-0 text-dark fw-bold">Receiver's Information</p>
                        <div className="mt-3">
                            <label>Receiver's Name</label>

                            <INPUT
                                type="text"
                                className="input"
                                value={receiverName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReceiverName(e.target.value)}
                                placeholder="Name"
                            />

                            <label className="mt-2">Receiver's Number</label>

                            <INPUT
                                type="number"
                                value={receiverPhone}
                                onChange={handleInputChange}
                                placeholder="Enter phone number"
                                maxLength={11}
                            />
                        </div>
                    </div>
                </div>
                <div className="container position-fixed bottom-0 start-50 translate-middle-x w-100 d-flex justify-content-center pb-3 bg-light">
                    <BLOCKBUTTON
                        disabled={!pickUp || !dropOff || !receiverName || !receiverPhone || !packageName || !packageQuantity}
                        className={"btn " + (!receiverName || !receiverPhone || !packageName || !packageQuantity ? "btn-inactive-primary" : "btn-primary")}
                        onClick={handleConfirm}
                    >Send Package</BLOCKBUTTON>
                </div>
            </div>
            <div
                className={'brand-modal w3-animate-zoom ' + (confirmModal ? 'd-flex' : 'd-none')}
                style={{ alignItems: 'center' }}
                onClick={() => setConfirmModal(false)}
            >
                <div
                    className='container bg-light p-3 mx-3'
                    style={{ borderRadius: '16px', maxWidth: '400px' }}
                >
                    <div className="border-bottom border-muted">
                        <h3 className='text-start my-0 pb-1'>Confirm</h3>
                        <p className="text-start text-muted mb-1">Kindly confirm package details</p>
                    </div>
                    <div>
                        <div className="mt-3">
                            <p className="text-muted mb-0">Package Name</p>
                            <h3 className="text-dark my-0">{packageName}</h3>
                        </div>
                        <div className="mt-3">
                            <p className="text-muted mb-0">Pick Up</p>
                            <h3 className="text-dark my-0">{pickUp}</h3>
                        </div>
                        <div className="mt-3">
                            <p className="text-muted mb-0">Drop Off</p>
                            <h3 className="text-dark my-0">{dropOff}</h3>
                        </div>
                        <div className="mt-3">
                            <p className="text-muted mb-0">Package Quantity</p>
                            <h3 className="text-dark my-0">{packageQuantity} - {inputQuantity}</h3>
                        </div>
                        <div className="mt-3">
                            <p className="text-muted mb-0">Receiver's Name</p>
                            <h3 className="text-dark my-0">{receiverName}</h3>
                        </div>
                        <div className="mt-3">
                            <p className="text-muted mb-0">Receiver's Number</p>
                            <h3 className="text-dark my-0">{receiverPhone}</h3>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <BUTTON
                            className='btn border border-primary me-3 mt-3 mb-3 w-50 text-primary'
                            onClick={() => setConfirmModal(false)}
                        >
                            Cancel
                        </BUTTON>
                        <BUTTON
                            className='btn btn-primary ms-3 mt-3 mb-3 w-50'
                            onClick={async () => {
                                Session.set("package_details", {
                                    package_name: packageName,
                                    package_category: packageCategory,
                                    packageQuantity: packageQuantity,
                                    package_quantity: inputQuantity,
                                    pickup_address: pickUp,
                                    receiver_address: dropOff,
                                    receiver_name: receiverName,
                                    receiver_phone: receiverPhone,
                                    pickUpCord,
                                    dropOffCord
                                })
                                navigate("/find-courier");
                            }}
                        >
                            Send Package
                        </BUTTON>
                    </div>
                </div>
            </div>
        </>
    )
};

export default SendPackage;
