import React from "react";
import { BLOCKBUTTON } from "./Forms";
import { useNavigate } from "react-router-dom";
import Session from "../Helpers/Session";



const InSufficientFunds = ({info}:{info:string}) => {
    const navigate = useNavigate();



    return (
        <>
            <div className={'brand-modal w3-animate-zoom d-flex align-items-center'}>
                <div className='w-100 bg-light px-3 mx-2' style={{ borderRadius: '16px', maxWidth: '400px' }}>

                    <h3 className='w3-center text-negative fs-3 fw-bold mt-5 mb-3'>Insufficient Balance</h3>

                    <p className='text-center mb-0 text-muted'>{info}</p>

                    <BLOCKBUTTON
                        className="btn-primary mb-4 mt-5"
                        onClick={() => {
                            Session.remove("package_details")
                            navigate("/fund-account");
                        }}
                    >
                        Fund Account
                    </BLOCKBUTTON>
                </div>
            </div>
        </>
    );
};

export default InSufficientFunds;