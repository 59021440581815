import React from 'react';
import Lottie from 'react-lottie';

interface LottieAnimationProps {
  animationData: any; // The animation data object
  width?: number; // Optional width of the animation
  height?: number; // Optional height of the animation
  loop?: boolean; // Optional loop setting
  autoplay?: boolean; // Optional autoplay setting
  speed?: number; // Optional speed setting
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  animationData,
  width = 200,
  height = 200,
  loop = true,
  autoplay = true,
  speed = 1,
}) => {
  const defaultOptions = {
    loop,
    autoplay,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        speed={speed}
      />
    </div>
  );
};

export default LottieAnimation;
