import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BLOCKBUTTON, CONTAINERCHECKBOX } from '../../Components/Forms';
import { UPDATE_ACCEPTED_TERMS } from '../../GraphQL/Mutations';
import { useMutation } from '@apollo/client';
import Session from '../../Helpers/Session';
import { Loading } from '../../Components/Loading';
import TermsAndPolicy from '../MenuPages/Account/Terms&Policy';

const AcceptTerms = ({ email, onClick }: { email: string, onClick?: () => void }) => {
    const [check, setCheck] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const navigate = useNavigate();

    const [updateAcceptedTerms, { loading }] = useMutation(UPDATE_ACCEPTED_TERMS, {
        variables: {
            email: email
        },
        onCompleted: () => {
            Session.showAlert({ str: 'You have accepted the terms and conditions.', type: 'success' });
            navigate('/login');
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            setCheck(!check)
            return;
        }
    });

    const handleAccept = () => {
        if (!check) {
            Session.showAlert({ str: 'Please accept the terms and conditions.', type: 'error' });
            return;
        }
        updateAcceptedTerms();
    };
    if (loading) return <Loading fullPage={false} message='Please wait...' />
    return (
        <>
        { 
            step === 1 && (
              <div className='container'>
                <div className="w3-animate-left">
                    <div className="d-flex flex-wrap align-content-center mt-3">
                            <i className="bi bi-arrow-left fw-bolder dark fs-3 ps-3"
                                onClick={onClick || (() => navigate(-1))}
                            ></i>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mt-5'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 128 128"
                            width="120px"
                            height="120px"
                            className='w3-center text-muted'
                        >
                            <path d="M24 1c-7.2 0-13 5.8-13 13v77c0 1.7 1.3 3 3 3s3-1.3 3-3V14c0-3.9 3.1-7 7-7h79c-1.3 2-2 4.4-2 7v100c0 3.9-3.1 7-7 7s-7-3.1-7-7v-10c0-1.7-1.3-3-3-3H4c-1.7 0-3 1.3-3 3v10c0 7.2 5.8 13 13 13h80c7.2 0 13-5.8 13-13V14c0-3.9 3.1-7 7-7s7 3.1 7 7v7h-7c-1.7 0-3 1.3-3 3s1.3 3 3 3h10c1.7 0 3-1.3 3-3V14c0-7.2-5.8-13-13-13H24zm23 20c-1.7 0-3 1.3-3 3s1.3 3 3 3h24c1.7 0 3-1.3 3-3s-1.3-3-3-3H47zM34 51c-1.7 0-3 1.3-3 3s1.3 3 3 3h50c1.7 0 3-1.3 3-3s-1.3-3-3-3H34zm.2 15c-1.7 0-3 1.3-3 3s1.3 3 3 3h35c1.7 0 3-1.3 3-3s-1.3-3-3-3h-35zM84 66a3 3 0 00-3 3 3 3 0 003 3 3 3 0 003-3 3 3 0 00-3-3zM7 107h74v7c0 2.6.8 5 2 7H14c-3.9 0-7-3.1-7-7v-7z" />
                        </svg>
                    </div>
                    <div className='mt-4'>
                        <h3 className='text-center text-wrap fw-bold fs-2'>Accept <span className='text-primary fw-bolder'>GOkari </span>Terms and Privacy Notice</h3>
                        <p className='text-center text-wrap fw-normal'>By selecting 'I hereby agree' below. I understand and I agree to the <Link to="#" onClick={() => setStep(2)}><span className='text-primary fw-bold'> Terms and Privacy Policy</span></Link></p>
                    </div>
                    <div className='mt-3'>
                        <CONTAINERCHECKBOX label='I hereby agree' onChange={setCheck} />
                    </div>
                    <BLOCKBUTTON
                        disabled={!check}
                        className={'btn mt-5 ' + (check ? 'btn-primary' : 'btn-inactive-primary')}
                        onClick={handleAccept}
                    >
                        Continue
                    </BLOCKBUTTON>
                </div>
            </div>  
            )
        }{
            step === 2 && (
                <TermsAndPolicy onClick={() => setStep(1)}/>
            )
        }
            
        </>
    )
}

export default AcceptTerms