interface CostConfig {
    distanceKMMultiplier: number;
    dayFixed: number;
    nightFixed: number;
    route: number;
    demandPattern: number;
    tolls: number;
    taxPercent: number;
    surchargesFixed: number;
    developmentLevyPercent: number;
    bookingFeePercent: number;
    waitTimePerMinuteFixed: number;
    discountPerTrip: number;
}

interface TripDetails {
    distanceKM: number;
    isNight: boolean;
    waitTimeMinutes: number;
    surgeMultiplier: number;
}

export function calculateTripFare(config: CostConfig, trip: TripDetails): number {
    // 1. Calculate the base distance fare
    const distanceFare = trip.distanceKM * config.distanceKMMultiplier;

    // 2. Add time of day charge
    const timeOfDayCharge = trip.isNight ? config.nightFixed : config.dayFixed;

    // 3. Calculate route charge (1.1 multiplier)
    const routeCharge = 0.1 * trip.distanceKM * config.distanceKMMultiplier;

    // 4. Calculate demand pattern/surge charge (1.5 multiplier)
    const demandPatternCharge = (trip.surgeMultiplier - 1) * 0.5 * trip.distanceKM * config.distanceKMMultiplier;

    // 5. Add tolls if any
    const tollsCharge = config.tolls;

    // 6. Calculate total trip fare before taxes and additional fees
    const totalTripFare = distanceFare + timeOfDayCharge + routeCharge + demandPatternCharge + tollsCharge;

    // 7. Calculate taxes
    const tax = (config.taxPercent / 100) * totalTripFare;
    const surcharges = (config.surchargesFixed / 100) * totalTripFare;
    const developmentLevy = (config.developmentLevyPercent / 100) * totalTripFare;

    // 8. Calculate total trip taxes and additional charges
    const totalTripTaxes = tax + surcharges + developmentLevy;

    // 9. Calculate other fees (booking fee and wait time)
    const bookingFee = (config.bookingFeePercent / 100) * totalTripFare;
    const waitTimeFee = trip.waitTimeMinutes * config.waitTimePerMinuteFixed;

    // 10. Calculate discount if any
    const discount = config.discountPerTrip;

    // 11. Calculate the final total payable amount
    const totalPayable = totalTripFare + totalTripTaxes + bookingFee + waitTimeFee - discount;

    return totalPayable;
};

export function isNightTime(): boolean {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    // Night is defined as between 12:00 AM (00:00) and 5:59 AM.
    return hours >= 0 && hours < 6;
}


