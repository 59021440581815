import React, { useState, useEffect, Suspense } from 'react';
import { Loading } from '../../../Components/Loading';
import UserContext from '../../../Helpers/UserContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Session from '../../../Helpers/Session';
import { useQuery } from '@apollo/client';
import { GET_TRANSACTIONS } from "../../../GraphQL/Queries";
import { APIResponse } from '../../../Helpers/General';
import FormatDate from '../../../Helpers/FormatDate';
import NumberToMoney from '../../../Helpers/numberToMoney';

const Transaction: React.FC = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [activeTab, setActiveTab] = useState('All');
    const [offset, setOffset] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const name = user?.fullname;

    const { refetch, loading } = useQuery(GET_TRANSACTIONS, {
        fetchPolicy: 'network-only',
        variables: {
            offset: offset
        },
        onCompleted(data) {
            setTransactions(data.getAllTransactions);
        },
        onError(error) {
            Session.showAlert({ str: error.message, type: 'error' });
            APIResponse(error);
            return;
        },
    });

    useEffect(() => {
        const tab = new URLSearchParams(location.search).get('tab') || 'All';
        setActiveTab(tab);
    }, [location.search]);

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        navigate({ search: `?tab=${tab}` });
    };
    const filteredTransactions = transactions.filter((transaction: Record<string, any>) => (activeTab === 'All' || transaction.status.toLowerCase() === activeTab.toLowerCase()));
    const formatTransaction = (transaction: Record<string, any>) => {
        if (transaction.status === 'Withdrawn' || transaction.status === 'Paid') {
            return (
                <div className="shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                <i className="bi bi-arrow-down-left fs-3 text-negative"></i>
                            </div>
                            <div className="ms-3">
                                <p className="mb-0 fw-bold">{name}</p>
                                <p className="mb-0 text-muted">{FormatDate(transaction.created_at)}</p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0 text-negative fs-6">&#8358;&nbsp;{NumberToMoney(transaction.amount)}</p>
                        </div>
                    </div>
                </div>
            )
        } else if (transaction.status === 'Deposited' || transaction.status === 'Received') {
            return (
                <div className="shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                <i className="bi bi-arrow-up-right fs-3 text-success"></i>
                            </div>
                            <div className="ms-3">
                                <p className="mb-0 fw-bold">{name}</p>
                                <p className="mb-0 text-muted">{FormatDate(transaction.created_at)}</p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0 text-success fs-6">&#8358;&nbsp;{NumberToMoney(transaction.amount)}</p>
                        </div>
                    </div>
                </div>
            )
        }
    }
    if (loading) {
        return <Loading fullPage={true} message={'Loading transactions...'} timing={false} />
    }
    return (
        <>
            <div className='w3-animate-left'>
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="/wallet">
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Transactions</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className="my-2">
                    <div className='d-flex align-items-center justify-content-around overflow-auto'>
                        <div
                            className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'All' ? 'bg-primary' : 'bg-light'}`}
                            onClick={() => handleTabClick('All')}
                        >
                            <span className={`fw-bold ${activeTab === 'All' ? 'text-light' : 'text-dark'}`}>All</span>
                        </div>
                        <div
                            className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'Paid' ? 'bg-primary' : 'bg-light'}`}
                            onClick={() => handleTabClick('Paid')}
                        >
                            <span className={`fw-bold ${activeTab === 'Paid' ? 'text-light' : 'text-dark'}`}>Paid</span>
                        </div>
                        <div
                            className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'Deposited' ? 'bg-primary' : 'bg-light'}`}
                            onClick={() => handleTabClick('Deposited')}
                        >
                            <span className={`fw-bold ${activeTab === 'Deposited' ? 'text-light' : 'text-dark'}`}>Deposited</span>
                        </div>
                        <div
                            className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'Withdrawn' ? 'bg-primary' : 'bg-light'}`}
                            onClick={() => handleTabClick('Withdrawn')}
                        >
                            <span className={`fw-bold ${activeTab === 'Withdrawn' ? 'text-light' : 'text-dark'}`}>Withdraw</span>
                        </div>
                    </div>
                </div>
                <Suspense fallback={<Loading />}>
                    <div className=" my-3">
                        {/* Render transactions based on the active tab */}
                        {filteredTransactions.length > 0 ? (
                            filteredTransactions.map((transaction: Record<string, any>) => formatTransaction(transaction))
                        ) : (
                            <>
                            </>
                        )}
                    </div>
                </Suspense>
            </div>
        </>
    );
};

export default Transaction;
