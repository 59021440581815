import React from 'react'
import { BLOCKBUTTON, BUTTON, INPUT } from '../../Components/Forms'
import { Link, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LOGIN, SEND_TOKEN } from '../../GraphQL/Mutations';
import { CURRENT_USER } from '../../GraphQL/Queries';
import { Validate } from '../../Helpers/Validate';
import UserContext from '../../Helpers/UserContext';
import OtpInput from 'react-otp-input';
import Session from '../../Helpers/Session';
import { Loading } from '../../Components/Loading';
import Redirect from '../../Helpers/Redirect';
// import { isLoggedIn } from '../../Helpers/IsLoggedIn';


const Login: React.FC = () => {
    const [email, setEmail] = React.useState('');
    const [step, setStep] = React.useState(1);
    const [otp, setOtp] = React.useState('');
    const {  setUser } = React.useContext(UserContext);
    const navigate = useNavigate();
    // const loggedIn = React.useMemo(() => isLoggedIn() && user, [user]);

    const handleCode = (value: string) => {
        // limit to digits only
        setOtp(value.replace(/\D/g, '0'));
    }
    const [refreshUser, { loading: refreshUserLoading }] = useLazyQuery(CURRENT_USER, {
        onCompleted: data => {
            setUser(data.currentUser);
            Session.setCookie('user', JSON.stringify(data.currentUser));
            Session.set("user", data.currentUser);
        }
    });

    const [sendToken] = useMutation(SEND_TOKEN, {
        variables: {
            email
        },
        onCompleted: (data) => {
            if (data.sendLoginToken) {
                Session.showAlert({ str: 'OTP has been sent to your email address.', type: 'success' });
                setStep(2);
            } else {
                Session.showAlert({ str: 'Email address not found.', type: 'error' });
                return;
            }
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const [login, { loading: loginLoading }] = useMutation(LOGIN, {
        variables: {
            email: email,
            token: otp
        },
        onCompleted: async (data) => {
            Session.setCookie("x-access-token", data.login.accessToken);
            Session.setCookie("x-refresh-token", data.login.refreshToken);
            await refreshUser();
            window.location.href = '/';
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const handleSendToken = () => {
        if (!Validate.email(email) || email.length < 3) {
            Session.showAlert({ str: 'Please enter your valid email.', type: 'warning' });
            return;
        }
        sendToken();
    }

    const handleLogin = async () => {
        if (otp.length !== 4) {
            Session.showAlert({ str: 'Please enter the 4-digit OTP sent to your email.', type: 'warning' });
            return;
        }
        if (!Validate.email(email) || email.length < 3) {
            Session.showAlert({ str: 'Please enter your valid email.', type: 'warning' });
            return;
        }
        await login();
    }

    if (loginLoading || refreshUserLoading) {
        return (
            <Loading fullPage={true} message="L.O.A.D.I.N.G" />
        )
    }

    return (
        <>
            <div className='container'>
                {
                    step === 1 &&
                    <div className='w3-animate-left'>
                        <div className='d-flex flex-wrap align-content-center'>
                            <Link className='dark me-5 fs-3 mt-3' to="/"><i className="bi bi-arrow-left"></i></Link>
                        </div>
                        <h3>Login into your account</h3>
                        <label>Email</label>

                        <INPUT type="email" value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} placeholder="Enter email address" />
                        {
                            email.length > 0 && !Validate.email(email) ?
                                <div className='text-error text-small mt-3 mb-4'><i className="bi bi-exclamation-triangle-fill"></i> Incorrect email address</div> :
                                <div className='text-muted text-small mt-3 mb-4'><i className="bi bi-exclamation-octagon"></i> We will send you an OTP to verify your email</div>
                        }


                        <BLOCKBUTTON disabled={!Validate.email(email) || email.length < 3} onClick={handleSendToken} className={Validate.email(email) ? 'btn-primary' : 'btn-inactive-primary'}>Continue</BLOCKBUTTON>

                        <div className='text-center mt-3'><span className='text-muted'>Don't have an account?</span> <Link className='text-primary' to="/register">Sign Up</Link></div>
                    </div>
                }
                {
                    step !== 1 &&
                    <div className={'w3-animate-right '}>
                        <div className='d-flex flex-wrap align-content-center'>
                            <Link className='dark me-5 fs-3 mt-3' onClick={() => setStep(1)} to="#"><i className="bi bi-arrow-left"></i></Link>
                        </div>
                        <h3>Enter Verification Code</h3>
                        <label className='mb-5'>Kindly enter the verification code sent to <div className='fs-6 mt-1 fw-bolder'>{email}</div></label>
                        <OtpInput
                            value={otp}
                            onChange={handleCode}
                            numInputs={4}
                            renderSeparator={<span className=' flex-grow-1'>&nbsp;</span>}
                            containerStyle="d-flex flex-wrap justify-content-center"
                            inputStyle="form-control flex-grow-1 fs-2 fw-bolder text-center"
                            renderInput={(props) => <input {...props} />}
                            inputType='number'
                        />


                        <BLOCKBUTTON disabled={otp.length !== 4} onClick={handleLogin} className={otp.length === 4 ? 'btn-primary mt-5' : 'btn-inactive-primary mt-5'}>Verify</BLOCKBUTTON>

                        <div className='text-center mt-3'><span className='text-muted'>Didn’t receive any code?</span> <BUTTON className='transparent text-primary p-0 mt-0' onClick={handleSendToken}>Resend OTP</BUTTON></div>
                    </div>
                }
            </div>
        </>
    );
}

export default Login
