import React from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { INPUT } from "../../../Components/Forms";
import { GET_ORDERS } from "../../../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import formatNumberToMoney from "../../../Helpers/numberToMoney";
import UserContext from "../../../Helpers/UserContext";
import Session from "../../../Helpers/Session";
import { APIResponse } from "../../../Helpers/General";
import { Loading } from "../../../Components/Loading";

const Orders = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = React.useState('All');
    const [searchQuery, setSearchQuery] = React.useState('');
    const [offset, setOffset] = React.useState(0);
    const [orders, setOrders] = React.useState([]);
    const location = useLocation();

    React.useEffect(() => {
        const tab = new URLSearchParams(location.search).get('tab') || 'All';
        setActiveTab(tab);
    }, [location.search]);

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        navigate({ search: `?tab=${tab}` });
    };

    const { refetch, loading } = useQuery(GET_ORDERS, {
        fetchPolicy: 'network-only',
        variables: {
            offset: offset
        },
        onCompleted(data) {
            setOrders(data.getAllOrders);
        },
        onError(error) {
            Session.showAlert({ str: error.message, type: 'error' });
            APIResponse(error);
            return;
        },
    })
    const getStatusClass = (status: string) => {
        switch (status) {
            case 'Delivered':
                return 'text-success approved';
            case 'Cancelled':
                return 'text-negative cancelled';
            case 'Pending':
                return 'order-pending';
            case 'In Transit':
                return 'order-in-transit';
            case 'Awaiting Pickup':
                return 'awaiting-pickup';
            case 'Courier Arrived for Pickup':
                return 'arrived-for-pickup';
            default:
                return 'text-muted bg-light';
        }
    };

    const filteredOrders = orders.filter((order: Record<string, any>) =>
        (activeTab === 'All' || order.status === activeTab) &&
        order.package_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const shortenStatus = (status: string) => {
        switch (status) {
            case 'Delivered':
                return 'Delivered';
            case 'Cancelled':
                return 'Cancelled';
            case 'Pending':
                return 'Pending';
            case 'In Transit':
                return 'In Transit';
            case 'Awaiting Pickup':
                return 'Awaiting Pickup';
            case 'Courier Arrived for Pickup':
                return 'Pickup Arrival';
            case 'Courier Arrived for Delivery':
                return 'Delivery Arrival';
            default:
                return 'Pending';
        }
    }
    if (loading) {
        return (
            <Loading fullPage={true} message={'Loading orders...'} timing={false} />
        )
    }
    return (
        <>
            <div className="w3-animate-left">
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="/"
                            >
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Orders</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className="container">
                    <div className="position-relative w-100">
                        <INPUT
                            type="text"
                            name="search"
                            className="input-search"
                            placeholder="Search package name..."
                            value={searchQuery}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
                        />
                        <i className="bi bi-search position-absolute top-50 translate-middle-y ps-2 fs-5 me-2" style={{ color: "#dee4f0" }}></i>
                    </div>
                    <div className="my-2">
                        <div className='d-flex align-items-center justify-content-around overflow-auto'>
                            <div
                                className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'All' ? 'bg-primary' : 'bg-light'}`}
                                onClick={() => handleTabClick('All')}
                            >
                                <span className={`fw-bold ${activeTab === 'All' ? 'text-light' : 'text-dark'}`}>All</span>
                            </div>
                            <div
                                className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'Pending' ? 'bg-primary' : 'bg-light'}`}
                                onClick={() => handleTabClick('Pending')}
                            >
                                <span className={`fw-bold ${activeTab === 'Pending' ? 'text-light' : 'text-dark'}`}>Pending</span>
                            </div>
                            <div
                                className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'In Transit' ? 'bg-primary' : 'bg-light'}`}
                                onClick={() => handleTabClick('In Transit')}
                            >
                                <span className={`fw-bold ${activeTab === 'In Transit' ? 'text-light' : 'text-dark'}`}>In Transit</span>
                            </div>
                            <div
                                className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'Delivered' ? 'bg-primary' : 'bg-light'}`}
                                onClick={() => handleTabClick('Delivered')}
                            >
                                <span className={`fw-bold ${activeTab === 'Delivered' ? 'text-light' : 'text-dark'}`}>Delivered</span>
                            </div>
                            <div
                                className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'Cancelled' ? 'bg-primary' : 'bg-light'}`}
                                onClick={() => handleTabClick('Cancelled')}
                            >
                                <span className={`fw-bold ${activeTab === 'Cancelled' ? 'text-light' : 'text-dark'}`}>Cancelled</span>
                            </div>
                        </div>
                    </div>
                    <div className="my-3">
                        {filteredOrders.length > 0 ? (
                            filteredOrders.map((order: Record<string, any>) => (
                                <div className="w3-animate-left" key={order.id}>
                                    <div className="shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                                        <div className="py-2 d-flex align-items-center justify-content-between">
                                            <p className="mb-0 fw-bold">{order.package_name}</p>
                                            <p className={`mb-0 fw-bold p-2 w3-round-xxlarge ${getStatusClass(order.status)}`}>{shortenStatus(order.status)}</p>
                                        </div>
                                        <div className="">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                            <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                        </svg>
                                                    </div>

                                                    <p className="mb-0 ps-2 text-muted">Pick Up: <span className="text-dark fw-bold">{order.pickup_address}</span></p>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center send-container me-3">
                                                    <p className="mb-0 text-muted text-nowrap">Delivery fee</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                    <p className="mb-0 ps-2 text-muted">Drop Off: <span className="text-dark fw-bold">{order.receiver_address}</span></p>
                                                </div>
                                                <div>
                                                    <p className="mb-0 fw-bold">&#8358;&nbsp;{formatNumberToMoney(+order.amount)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-center py-5">
                                {activeTab === 'All' ? (
                                    <div className='d-flex h-100 align-items-center justify-content-center' style={{ minHeight: '40svh', marginBottom: "120px" }}>
                                        <div className=' fade-overlay'>
                                            <div className='d-flex align-items-center justify-content-center flex-column'>
                                                <img src="./images/3d/box.png" alt="" width={"100px"} />
                                                <p className='mb-0 fw-bold text-muted text-center text-wrap mt-3 w-75' >You currently do not have any order.</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='d-flex h-100 align-items-center justify-content-center' style={{ minHeight: '40svh', marginBottom: "120px" }}>
                                        <div className=' fade-overlay'>
                                            <div className='d-flex align-items-center justify-content-center flex-column'>
                                                <img src="./images/3d/box.png" alt="" width={"100px"} />
                                                <p className='mb-0 fw-bold text-muted text-center text-wrap mt-3 w-75' >You currently do not have any {activeTab.toLowerCase()} order.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
};

export default Orders;
