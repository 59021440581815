function isImageBlurry({dataUrl, threshold = 400}: {dataUrl: string, threshold?: number}): Promise<boolean> {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) return resolve(false);

            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);

            const imageData = ctx.getImageData(0, 0, img.width, img.height);
            const pixels = imageData.data;
            const gray: number[] = [];

            // Convert to grayscale
            for (let i = 0; i < pixels.length; i += 4) {
                const avg = (pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3;
                gray.push(avg);
            }

            const laplacian: number[] = [];
            const width = img.width;
            const height = img.height;

            // Apply Laplacian filter
            for (let y = 1; y < height - 1; y++) {
                for (let x = 1; x < width - 1; x++) {
                    const idx = y * width + x;
                    const laplacianValue =
                        -gray[idx - width - 1] - gray[idx - width] - gray[idx - width + 1]
                        -gray[idx - 1] + 8 * gray[idx] - gray[idx + 1]
                        -gray[idx + width - 1] - gray[idx + width] - gray[idx + width + 1];
                    laplacian.push(laplacianValue);
                }
            }

            // Calculate variance
            const mean = laplacian.reduce((sum, val) => sum + val, 0) / laplacian.length;
            const variance = laplacian.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / laplacian.length;
            console.log({mean, variance, threshold});
            resolve(variance < threshold); // Adjust threshold as needed
        };
        img.src = dataUrl;
    });
}

export default isImageBlurry;