import React, { useContext } from 'react';
import userPhoto from '../../assets/images/photo.png';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UPDATE_PROFILE, LOGOUT } from '../../GraphQL/Mutations';
import { Link, useNavigate } from 'react-router-dom';
import Session from '../../Helpers/Session';
import { BLOCKBUTTON, BUTTON, INPUT } from '../../Components/Forms';
import UserContext from '../../Helpers/UserContext';
import { Validate } from '../../Helpers/Validate';
import { Loading } from '../../Components/Loading';
import { CURRENT_USER } from '../../GraphQL/Queries';
import Menu from '../../Components/Menu';
import { getImage } from '../../Helpers/FileHandling';
import { convertToLocalPhoneNumber, validateNigerianPhoneNumber } from '../../Helpers/General';
import isImageBlurry from '../../Helpers/isImageBlurry';

const Account: React.FC = () => {
    const [step, setStep] = React.useState<number>(1);
    const navigate = useNavigate();
    const myContext = useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [photo, setPhoto] = React.useState<string | null>(user?.photo || userPhoto);
    const [fullname, setFullname] = React.useState<string>(user?.fullname || '');
    const [phone, setPhone] = React.useState<string>(convertToLocalPhoneNumber(user?.phone) || '');
    const [handleModel, setHandleModel] = React.useState<boolean>(false);

    const [refreshUser, {loading: refreshUserLoading}] = useLazyQuery(CURRENT_USER, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            Session.setCookie('user', JSON.stringify(data.currentUser));
            Session.set("user", data.currentUser);
            myContext.setUser(data.currentUserCourier);
        }
    });

    const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
        variables: {
            fullname,
            phoneNumber: phone,
            photo: photo
        },
        onCompleted: (data) => {
            refreshUser();
            Session.showAlert({ str: 'Profile updated successfully.', type: 'success' });
            setStep(1);
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = async () => {
                let isBlurry = await isImageBlurry({ dataUrl: reader.result, threshold: 1000 })
                if (isBlurry) {
                    Session.showAlert({ str: 'Please use a clear profile image.', type: 'error' });
                    setPhoto(user?.photo || userPhoto);
                    return;
                } else {
                    setPhoto(reader.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectPhoto = (event: any) => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageUpload;
        input.click();
    };

    const handleUpdateProfile = () => {
        if (!fullname || !Validate.string(fullname) || fullname.length < 3) {
            Session.showAlert({ str: 'Please enter your fullname.', type: 'error' });
            return;
        }
        if (!validateNigerianPhoneNumber(phone)) {
            Session.showAlert({ str: 'Please enter your valid phone number.', type: 'error' });
            return;
        }
        if (!photo) {
            setPhoto(null);
        }
        updateProfile();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
    
        // Only allow numeric input
        const isNumeric = /^[0-9]*$/.test(value);
    
        // Allow input only if it's exactly 11 digits or empty (for clearing the field)
        if (isNumeric && (value.length <= 11)) {
            setPhone(value);
        }
      };

    const [logout, { loading: logoutLoading }] = useMutation(LOGOUT, {
        onCompleted: (data) => {
            setHandleModel(false);
            Session.clearAllCookies();
            myContext.setUser(null);
            navigate('/login');
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    const handleLogout = () => {
        logout();
    };

    if (logoutLoading || loading || refreshUserLoading) {
        return (
            <Loading fullPage={true} message={logoutLoading ? "LOGGING OUT" : "UPDATING PROFILE..."} timing={false} />
        );
    }

    return (
        <>
            <div className=''>
                {step === 1 && (
                    <div className='w3-animate-left'>
                        <div className='bg-primary'>
                            <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                                <div></div>
                                <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                                    <h3 className='w3-center text-light mb-0'>Account</h3>
                                </div>
                                <div className='me-3'></div>
                            </div>
                        </div>
                        <div className="px-3 w-100">
                            <div className='d-flex align-items-center justify-content-between mt-3 py-3'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img
                                        src={photo ? getImage(photo) : userPhoto}
                                        alt=""
                                        className='rounded-circle'
                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                    />
                                    <h3 className='w3-center ps-4 my-0'>{fullname}</h3>
                                </div>
                                <BUTTON
                                    className='btn btn-primary my-0 py-3'
                                    onClick={() => setStep(2)}
                                >
                                    Edit Profile
                                </BUTTON>
                            </div>
                            <div>
                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-3 w3-animate-zoom'
                                    onClick={() => navigate('/faqs')}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-chat-square-dots text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>FAQs</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                //@ts-ignore
                                    onClick={() => Website2APK.rateUs()}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-star text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Rate this App</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>

                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                    onClick={() => navigate('/customer-support')}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-headset text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Customer Support</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                    onClick={() => navigate('/term-and-policy')}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-clipboard-data text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Privacy & Policy</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                                <div
                                    className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                    onClick={() => setHandleModel(true)}
                                    style={{ marginBottom: '80px' }}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-box-arrow-right text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Sign Out</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                            </div>
                        </div>
                        <Menu />
                        <div
                            className={'brand-modal w3-animate-zoom ' + (handleModel ? 'd-flex' : 'd-none')}
                            style={{ alignItems: 'center' }}
                            onClick={() => setHandleModel(false)}
                        >
                            <div
                                className='container bg-light p-3 mx-3'
                                style={{ borderRadius: '16px', maxWidth: '400px' }}
                            >
                                <h3 className='text-center'>Sign Out</h3>
                                <div>
                                    <p className='text-center text-wrap'>
                                        Are you sure you want to sign out?
                                    </p>
                                </div>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <BUTTON
                                        className='btn border border-dark mt-4 mb-3'
                                        onClick={() => setHandleModel(false)}
                                    >
                                        Cancel
                                    </BUTTON>
                                    <BUTTON
                                        className='btn brand negative mt-4 mb-3'
                                        onClick={handleLogout}
                                    >
                                        Sign Out
                                    </BUTTON>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div className='w3-animate-left'>
                        <div className='bg-primary'>
                            <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                                <div>
                                    <Link className="dark fs-3 ps-3" onClick={() => setStep(1)} to="#">
                                        <i className="bi bi-arrow-left text-light fw-bolder"></i>
                                    </Link>
                                </div>
                                <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                                    <h3 className='w3-center text-light mb-0'>Edit Profile</h3>
                                </div>
                                <div className='me-3'></div>
                            </div>
                        </div>
                        <div className="container">
                            <div
                                className='border border-primary rounded-circle mx-auto mt-5 d-flex align-items-center justify-content-center position-relative'
                                style={{ width: '150px', height: '150px' }}
                            >
                                <img
                                    src={photo ? getImage(photo) : userPhoto}
                                    className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow position-relative"
                                    alt=''
                                    onClick={(event) => handleSelectPhoto(event)}
                                />
                                <i className="bi bi-pencil-square text-primary fs-3 position-absolute bottom-0 end-0 py-1 px-2 w3-sand w3-circle w3-hover-shadow w3-animate-zoom"></i>
                            </div>
                            <label className='mt-3'>Full Name</label>
                            <INPUT
                                type="text"
                                value={fullname}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFullname(e.target.value)}
                                placeholder="Enter full name"
                            />
                            <label className='mt-3'>Phone Number</label>
                            <INPUT
                                type="number"
                                value={phone}
                                onChange={handleInputChange}
                                placeholder="Enter phone number"
                                maxLength={11}
                            />
                            <BLOCKBUTTON
                                className='btn btn-primary mt-5'
                                onClick={handleUpdateProfile}
                            >
                                Save Changes
                            </BLOCKBUTTON>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div className='w3-animate-zoom'>

                    </div>
                )}
            </div>
        </>
    );
};

export default Account;
