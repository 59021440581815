const Redirect = (path: string, basePath = '') => {
    let myPath = path.startsWith('/') ? path : '/' + path;
    myPath = myPath.startsWith(basePath) ? myPath : basePath + myPath;
    if (myPath === basePath && basePath !== '/') {
        myPath = basePath + basePath.substring(1);
    }
    window.location.href = myPath;
};

export default Redirect;
