import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const Menu: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (path: string) => {
        return location.pathname === path;
    };
    return (
        <>
            <div className='menu-container bg-light mt-4'>
                <div className='d-flex justify-content-around align-items-center pt-1'>
                    <Link to="/">
                    <div className={'w3-center'}
                    >
                        <div className={`${isActive('/') && 'menu-active'}`}>
                            <div className='d-flex flex-column'>
                                <i className="bi bi-house-fill fs-3"></i>
                                <p>Home</p>
                            </div>
                        </div>

                    </div>
                    </Link>
                    {/* <div className={'w3-center'}
                    onClick={() => navigate('/order')}
                    >
                        <div className={`${isActive('/order') && 'menu-active'}`}>
                            <div className='d-flex flex-column'>
                                <i className="bi bi-bicycle fs-3"></i>
                                <p>Orders</p>
                            </div>
                        </div>

                    </div> */}
                    <Link to="/wallet">
                    <div className='w3-center'
                    >
                        <div className={`${isActive('/wallet') && 'menu-active'}`}>
                            <div className='d-flex flex-column'>
                                <i className="bi bi-wallet fs-3"></i>
                                <p>Wallet</p>
                            </div>
                        </div>

                    </div>
                    </Link>
                    <Link to="/account">
                    <div className='w3-center'
                    >
                        <div className={`${isActive('/account') && 'menu-active'}`}>
                            <div className='d-flex flex-column'>
                                <i className="bi bi-person-fill fs-3"></i>
                                <p>Account</p>
                            </div>
                        </div>

                    </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Menu;