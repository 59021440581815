import { DateTime } from 'luxon';

function FormatDate(sqlDateTime: string): string {
    const dateTime = DateTime.fromSQL(sqlDateTime);

    const formattedDate = dateTime.toFormat('MMM. dd, yyyy');

    return formattedDate;
}

export default FormatDate;