
export const QBConfig = {
    credentials: {
        appId: 104469,
        accountKey: 'ack_dMCZsxJpHsH_KJkUq6dQ',
        authKey: 'ak_wbRtvbptJbZWxrV',
        authSecret: 'as_8eTVFBkLexgnDFE',
        sessionToken: '4XT4az8OdXFGgksS6hWnSb4SASDXrUfFK5ar6dNM2KU',
    },
    appConfig: {
        maxFileSize: 10 * 1024 * 1024,
        sessionTimeOut: 122,
        chatProtocol: {
            active: 2,
        },
        debug: true,
        enableForwarding: true,
        enableReplying: true,
        regexUserName: '^(?=[a-zA-Z])[-a-zA-Z_ ]{3,49}(?<! )$',
        endpoints: {
            api: 'api.quickblox.com',
            chat: 'chat.quickblox.com',
        },
        webrtc: {
            disconnectTimeInterval: 30,
        },
        streamManagement: {
            enable: true,
        },
    },
};