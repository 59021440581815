import React from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import UserContext from "../../../Helpers/UserContext";
import Session from "../../../Helpers/Session";
import UserCurrentLocation from "../../../Helpers/UserCurrentLocation";
import { BLOCKBUTTON, BUTTON } from "../../../Components/Forms";
import NumberToMoney from "../../../Helpers/numberToMoney";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_LOCATION} from "../../../GraphQL/Mutations";
import { APIResponse } from "../../../Helpers/General";
import { calculateTripFare, isNightTime } from "../../../Helpers/CalculatePrice";


const ChangeDest = ({
    setChangeDest,
    setAmount,
    setDistance,
    setDuration,
    setDirections,
    amount,
    distance,
    duration,
}: {
    setChangeDest: (changeDest: boolean) => void,
    setAmount: (amount: number) => void,
    setDistance: (distance: number) => void,
    setDuration: (duration: string) => void,
    setDirections: (directions: google.maps.DirectionsResult) => void,
    amount: number,
    distance: number,
    duration: string,
}) => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [packageDetails, setPackageDetails] = React.useState(Session.get('package_details'));
    const [pickUp, setPickUp] = React.useState<string>(packageDetails.pickup_address);
    const [pickUpCord, setPickUpCord] = React.useState<google.maps.LatLngLiteral>(packageDetails.pickUpCord);
    const [dropOffCord, setDropOffCord] = React.useState<google.maps.LatLngLiteral>(packageDetails.dropOffCord);
    const [dropOff, setDropOff] = React.useState<string>(packageDetails.receiver_address);
    const [userLocation, setUserLocation] = React.useState({ latitude: 0, longitude: 0 });
    const [confirmChange, setConfirmChange] = React.useState<boolean>(false);
    const COST = JSON.parse(Session.getCookie("config")).cost;
    const [inSuffientFunds, SetInSuffientFunds] = React.useState(false);

    const handleUserLocation = async () => {
        const miiLocation = await UserCurrentLocation() || { latitude: 0, longitude: 0 };
        setUserLocation(miiLocation);
    };

    const {
        ready: pickupReady,
        value: pickupValue,
        suggestions: { status: pickupStatus, data: pickupData },
        setValue: setPickupValue,
        clearSuggestions: clearPickupSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: { componentRestrictions: { country: "ng" } },
        debounce: 300,
    });

    const {
        ready: dropoffReady,
        value: dropoffValue,
        suggestions: { status: dropoffStatus, data: dropoffData },
        setValue: setDropoffValue,
        clearSuggestions: clearDropoffSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: { componentRestrictions: { country: "ng" } },
        debounce: 300,
    });

    React.useEffect(() => {
        setPickupValue(pickUp, false);
        setDropoffValue(dropOff, false);
    }, [pickUp, dropOff]);

    const handlePickUpSelect = async (address: string) => {
        setPickupValue(address, false);
        clearPickupSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            setPickUp(address);
            setPickUpCord({ lat, lng });
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleDropOffSelect = async (address: string) => {
        setDropoffValue(address, false);
        clearDropoffSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            setDropOff(address);
            setDropOffCord({ lat, lng });
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleUseCurrentLocation = async () => {
        handleUserLocation();
        if (userLocation.latitude && userLocation.longitude) {
            try {
                const results = await getGeocode({ location: { lat: userLocation.latitude, lng: userLocation.longitude } });
                if (results[0]) {
                    const address = results[0].formatted_address;
                    setPickUp(address);
                    setPickupValue(address, false);
                    setPickUpCord({ lat: userLocation.latitude, lng: userLocation.longitude });
                    clearPickupSuggestions();
                }
            } catch (error) {
                console.error('Error: ', error);
            }
        }
    };

    React.useEffect(() => {
        if (pickUpCord && dropOffCord) {
            const directionsService = new google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: pickUpCord,
                    destination: dropOffCord,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK && result) {
                        setDirections(result);
                        const route = result.routes[0];
                        const leg = route.legs[0];
                        if (leg) {
                            setDistance(leg.distance?.value || 0);
                            const calPrice = calculateTripFare(COST, { distanceKM: (Math.round(leg.distance?.value || 0) / 1000), isNight: isNightTime(), surgeMultiplier: 1.5, waitTimeMinutes: 1 });
                            setAmount(calPrice);
                            // setAmount((Math.round(leg.distance?.value || 0) / 1000) * COST.distanceKMMultiplier);
                            setDuration(leg.duration?.text || "");
                        }
                    } else {
                        console.error(`Directions request failed: ${status}`);
                    }
                }
            );
        }
    }, [pickUpCord, dropOffCord]);


    const [updateOrderLocation] = useMutation(UPDATE_ORDER_LOCATION, {
        variables: {
            orderId: packageDetails.id,
            receiverAddress: dropOff,
            distance: distance,
            duration: duration,
            amount: amount
        },
        onCompleted: (data) => {
            if (data.updateOrderLocation) {
                const updatedPackageDetails = {
                    ...packageDetails,
                    dropOffCord,
                    pickUpCord,
                    ...data.updateOrderLocation
                };
                setPackageDetails(updatedPackageDetails);
                Session.set('package_details', updatedPackageDetails);
                setConfirmChange(false);
                setChangeDest(false);
                Session.showAlert({ str: 'Your Order drop off address has been Updated.', type: 'success' });
            }
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const handleUpdateOrderLocation = () => {
        if (amount > user?.balance) {
            Session.showAlert({ str: "You don't have enough balance to update this order drop off location. Fund your wallet.", type: 'error' });
            SetInSuffientFunds(true);
            return;
        }
        updateOrderLocation();
    }
    return (
        <>  {!confirmChange ? (
            <div
                className={'brand-modal w3-animate-bottom w-100'}
                style={{ alignItems: 'end' }}
            >
                <div
                    className='w-100 bg-light p-3 w-100 brand-modal-content w3-animate-bottom'
                    style={{ zIndex: 1000, minHeight: "80svh" }}
                >
                    <div className='px-2 pt-3 position-relative'>
                        <div className='d-flex align-items-center justify-content-center w3-light-gray w3-circle' style={{ height: '40px', width: '40px' }}>
                            <i className='bi bi-x fs-2'
                                onClick={() => setChangeDest(false)}
                            ></i>
                        </div>
                        <h3 className="fs-5 fw-bold mt-2 mb-0">Set Pick up and Drop off location</h3>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center flex-column pt-2">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                    </svg>
                                </div>
                                <i className="bi bi-three-dots-vertical text-muted fs-3"></i>
                                <i className="bi bi-geo-alt-fill text-primary fs-3"></i>
                            </div>
                            <div className="d-flex align-items-center flex-column w-100 ms-2 ">
                                <div className="w-100 mb-2 ">
                                    <input
                                        value={pickupValue}
                                        onChange={(e) => setPickupValue(e.target.value)}
                                        disabled={!pickupReady}
                                        placeholder="Enter pick up location"
                                        className="form-control w-100"
                                        readOnly
                                    />
                                    {pickupStatus === "OK" && (
                                        <ul className="list-group position-absolute w-100 z-2">
                                            <li className="list-group-item text-primary" onClick={handleUseCurrentLocation}>
                                                Use Current Location
                                            </li>
                                            {pickupData.map((suggestion) => (
                                                <li
                                                    key={suggestion.place_id}
                                                    onClick={() => handlePickUpSelect(suggestion.description)}
                                                    className="list-group-item"
                                                >
                                                    {suggestion.description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                </div>
                                <div className="w-100">
                                    <input
                                        value={dropoffValue}
                                        onChange={(e) => setDropoffValue(e.target.value)}
                                        disabled={!dropoffReady}
                                        placeholder="Enter drop off location"
                                        className="form-control w-100"
                                    />
                                    {dropoffStatus === "OK" && (
                                        <ul className="list-group position-absolute w-100 z-2">
                                            {dropoffData.map((suggestion) => (
                                                <li
                                                    key={suggestion.place_id}
                                                    onClick={() => handleDropOffSelect(suggestion.description)}
                                                    className="list-group-item"
                                                >
                                                    {suggestion.description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between pending w3-round-large mx-auto mt-4 px-4 py-3"
                            style={{ maxWidth: "600px" }}
                        >
                            <i className="bi bi-exclamation-octagon-fill fs-4 text-negative"></i>

                            <p className="my-0 ms-3 fw-bold">By changing your destination address, there will be a change in Price and Arrival time.</p>
                        </div>
                        <div className="container position-fixed bottom-0 start-50 translate-middle-x w-100 d-flex justify-content-center pb-3 bg-light">
                            <BLOCKBUTTON
                                onClick={() => setConfirmChange(true)}
                                className={"btn-primary "}
                                disabled={!pickupValue || !dropoffValue}
                            >
                                Change Location
                            </BLOCKBUTTON>
                        </div>

                    </div>
                </div>
            </div>
        ) : (
            <div
                className={'brand-modal w3-animate-bottom w-100'}
                style={{ alignItems: 'end' }}
            >
                <div
                    className='w-100 bg-light p-3 w-100 brand-modal-content w3-animate-bottom'
                    style={{ zIndex: 1000 }}
                >
                    <h3 className="fs-5 fw-bold mt-2 mb-2">Change in price and time</h3>

                    <p className="my-0">
                        By changing your destination, there will be a change in price of <span className="fw-bold">&#8358;&nbsp;{NumberToMoney(amount)}</span> and arrival time of the package to the drop off location will take <span className="fw-bold">{duration}</span>.
                    </p>

                    <div className="w-100 d-flex justify-content-between mt-3">
                        <BUTTON
                            onClick={() => setChangeDest(false)}
                            className="w-50 border border-primary border-2 mt-4 text-primary"
                        >
                            No, Cancel
                        </BUTTON>
                        <div className="w-25"></div>
                        <BUTTON
                            onClick={() => handleUpdateOrderLocation()}
                            className={"btn-primary w-50"}
                        >
                            Yes, I agree
                        </BUTTON>
                    </div>
                </div>
            </div>
        )}


        </>
    )
}

export default ChangeDest;