import React, { useState } from 'react';


export const BUTTON = (props: any) => {
  return (
    <button
      type={props.type || "button"}
      className={`btn ${props.className || ""}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export const SELECTOPTION = (props: any) => {
  return (
    <select
      className={`form-control ${props.className || ""}`}
      value={props.value}
      onChange={props.onChange}
      about={props.about}
    >
      {props.children}
    </select>
  );
}
interface SelectDropdownProps {
  items: string[];
  onSelect: (value: any) => void;
  showSearch?: boolean;
  value?: string;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
}

export const SELECT_DROPDOWN: React.FC<SelectDropdownProps> = ({
  items,
  onSelect,
  showSearch = false,
  value,
  className,
  placeholder,
  readOnly,
  required,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleItemClick = (item: string) => {
    onSelect(item);
    setIsOpen(false);
  };

  const filteredItems = items.filter(item =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="position-relative">
      <input
        type="text"
        value={value}
        onClick={() => setIsOpen(!isOpen)}
        className={`form-control ${className || ""}`}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

        }}
      />
      {isOpen && (
        <div className="dropdown-menu show position-absolute top-100 start-0 mt-2 w-100" style={{maxHeight: '30svh', overflowY: 'auto'}}>
          {showSearch && (
            <div className='px-2'>
              <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search..."
              className="form-control py-2 m-0"
            />
            </div>
          )}
          {filteredItems.map((item, index) => (
            <div
              key={index}
              className="dropdown-item py-2 w3-round-large"
              onClick={() => handleItemClick(item)}
              role="button"
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export const BLOCKBUTTON = (props: any) => {
  return (
    <button
      disabled={props.disabled}
      type={props.type || "button"}
      className={`btn w-100 ${props.className || ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export const INPUT = (props: any) => {
  return (
    <input
      type={props.type || "text"}
      className={`form-control ${props.className || ""}`}
      placeholder={props.placeholder}
      value={props.value}
      pattern={props.pattern}
      onChange={props.onChange}
      readOnly={props.readOnly}
      required={props.required}
      disabled={props.disabled}
      ref={props.ref}
      maxLength={props.maxLength}
    />
  );
}



interface CheckboxContainerProps {
  label: string;
  initialChecked?: boolean;
  onChange?: (checked: boolean) => void;
  border?: boolean;
  className?: string;
}
export const CONTAINERCHECKBOX: React.FC<CheckboxContainerProps> = ({ label, initialChecked = false, onChange, border, className }) => {
  const [check, setCheck] = useState(initialChecked);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setCheck(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  const handleContainerClick = () => {
    setCheck(!check);
    if (onChange) {
      onChange(!check);
    }
  };
  const confirmBorder = () => {
    if (check && border) {
      return 'pending checked-container '
    } else if (check && !border) {
      return ' '
    } else {
      return 'bg-light '
    }
  }

  return (
    <div
      className={'d-flex align-items-center p-3 w3-round-large ' + confirmBorder() + className}
      onClick={handleContainerClick}
    >
      <input
        type="checkbox"
        name="accept_terms"
        id="accept_terms"
        className={'me-3 form-check-input d-block mt-0'}
        checked={check}
        onChange={handleCheckboxChange}
      />
      <p className='mb-0'>{label}</p>
    </div>
  );
};